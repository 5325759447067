import React from "react"
import { Button, FormControl, InputGroup, Modal } from "react-bootstrap"
import Icon from "../Icon/Icon"

interface Props {
  isOpen: boolean
  onClose: () => void
  onExport: (format: string) => void
}

const ExportModal = ({ isOpen, onClose, onExport }: Props) => {

  const powerbiUrl = `https://app.pl4nner.com:8443/export/powerbi/?format=xlsx`;

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Exportar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=" mb-3">
          <span>Importar a PowerBI:</span>
          <div>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Recipient's username"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={powerbiUrl}
                readOnly
              />
              <InputGroup.Append>
                <Button
                  variant="outline-secondary"
                  title="Copiar al portapapeles"
                  onClick={() => navigator.clipboard.writeText(powerbiUrl)}
                >
                  <Icon name="copy" far />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
        </div>
        <p>Descargar archivo</p>
        <div className="d-flex justify-content-center">
          <a
            className="btn btn-normal mr-2"
            href={powerbiUrl}
            target="_blank"
            rel="noreferrer"
          >
            Excel
          </a>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ExportModal
