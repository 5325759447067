
import React, { useState, useEffect } from 'react'
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import config from '../../../../../config'
import navigation from '../../../../../navigation'
import { Can } from '../../../../../Casl/Casl'

import { useAuth } from '../../../../../Hooks/UseAuth'

import { ProjectDefaults } from '../../../../../Types/Project'

import ProjectsActions from '../../../../../Redux/Actions/ProjectsActions'
import InterfaceActions from '../../../../../Redux/Actions/InterfaceActions'

import DateFunctions, { getNonWorkingDaysFromRange } from '../../../../../Helpers/DateFunctions'

import MainLayout from '../../../../../Components/MainLayout/MainLayout'
import InputFormControl from '../../../../../Components/InputFormControl/InputFormControl'
import SelectFormControl from '../../../../../Components/SelectFormControl/SelectFormControl'
import InputMaskedControl from '../../../../../Components/InputMaskedControl/InputMaskedControl'
import MainLayoutContentHeader from '../../../../../Components/MainLayout/MainLayoutContentHeader'
import MainLayoutContentBody from '../../../../../Components/MainLayout/MainLayoutContentBody'
import Toast from '../../../../../Components/Toast/Toast'

import './ProjectInfoPage.scss'

const ProjectInfoPage = (props: any) => {

    const dispatch = useDispatch()

    const { ability, userData } = useAuth()

    const project = useSelector((state: any) => state.ProjectsReducer.rowData)
    const loadProjectStatus = useSelector((state: any) => state.ProjectsReducer.loadRowStatus)

    const [ state, setState ] = useState({
        project: { ...project },
        editingProject: false,
        editingClient: false,
        editingSupervisor: false,
        editingSettings: false,
        projectLoaded: false
    })

    const [ tab, setTab ] = useState('PROJECT')
    const [ editable, setEditable ] = useState(false)

    const [ menu, submenu ] = navigation.getNavigation(
        props.match.params._id,
        'home',
        'info'
    )

    const unSetEditable = () => {

        switch (tab) {
            case 'PROJECT':
                resetProjectCard()
                break
            case 'CLIENT':
                resetClientCard()
                break
            case 'SUPERVISOR':
                resetSupervisorCard()
                break
            case 'SETTINGS':
                resetSettingsCard()
                break
        }

        setEditable(false)
    }

    const changeTab = (tab: string) => {

        if (!editable) {

            setTab(tab)
        }
    }

    const loadProject = () => {

        if (loadProjectStatus !== 'none') return

        dispatch(ProjectsActions.getRowAction(props.match.params._id))
    }
    loadProject()

    useEffect(() => {

        project.nonWorkingDays = project.nonWorkingDays.map((day: string) => {

            return moment(day, 'YYYY-MM-DD').format('YYYY-MM-DD')
        })

        if (project.settings && !project.settings.workTimeStart) {

            project.settings.workTimeStart = ProjectDefaults.settings_workTimeStart
        }

        if (project.settings && !project.settings.workTimeEnd) {

            project.settings.workTimeEnd = ProjectDefaults.settings_workTimeEnd
        }

        setState({ ...state, project })
    }, [ loadProjectStatus ])

    const handleProject = (project: any) => {

        setState({
            ...state,
            project
        })
    }

    const handleClient = (client: any) => {

        const projectModified = { ...state.project }
        projectModified.client = client

        setState({
            ...state,
            project: projectModified
        })
    }

    const handleSupervisor = (supervisor: any) => {

        const project = { ...state.project }
        project.supervisor = supervisor

        setState({
            ...state,
            project
        })
    }

    const handleMethod = (method: any) => {

        const settings = { ...state.project.settings }
        settings.method = method

        const project = {
            ...state.project,
            settings
        }

        setState({
            ...state,
            project
        })
    }

    const handleLookahead = (lookahead: any) => {

        const settings = { ...state.project.settings }
        settings.lookahead = lookahead

        const project = {
            ...state.project,
            settings
        }

        setState({
            ...state,
            project
        })
    }

    const toggleWorkDay = (day: string) => {

        const project = { ...state.project }

        project.settings[ day ] = !project.settings[ day ]

        setState({
            ...state,
            project
        })
    }

    const handleWorktime = (key: string, value: any) => {

        const project = { ...state.project }

        if (key === 'start') {

            project.settings.workTimeStart = value
        } else if (key === 'end') {

            project.settings.workTimeEnd = value
        }

        setState({
            ...state,
            project
        })
    }

    const handleWorkTimeAtWeek = (value: string) => {

        const project = { ...state.project }

        project.settings.workTimeAtWeek = value

        setState({
            ...state,
            project
        })
    }

    const daysEnabled = () => {

        const {
            settings,
        } = state.project

        let days = 0

        days += settings.workMonday ? 1 : 0
        days += settings.workTuesday ? 1 : 0
        days += settings.workWednesday ? 1 : 0
        days += settings.workThursday ? 1 : 0
        days += settings.workFriday ? 1 : 0
        days += settings.workSaturday ? 1 : 0
        days += settings.workSunday ? 1 : 0

        return days
    }

    const checkNonWorkingDays = () => {

        let valid = true

        state.project.nonWorkingDays.map((day: string) => {

            if (day === 'Fecha inválida' || day === '') {

                valid = false
            }
        })

        return valid
    }

    const checkTimeExtensions = () => {

        let valid = true

        state.project.timeExtensions.map((days: number) => {

            if ((days * 1) === 0) {

                valid = false
            }
        })

        return valid
    }

    const addNonWorkingDay = () => {

        const {
            nonWorkingDays,
        } = state.project

        nonWorkingDays.push('')

        project.nonWorkingDays = nonWorkingDays

        setState({
            ...state,
            project
        })
    }

    const removeNonWorkingDay = (index: number) => {

        const {
            nonWorkingDays,
        } = state.project

        nonWorkingDays.splice(index, 1)

        project.nonWorkingDays = nonWorkingDays

        setState({
            ...state,
            project
        })
    }

    const updateNonWorkingDay = (i: number, e: any) => {

        const value = typeof (e) === 'string' ? e : e.target.value

        const {
            nonWorkingDays,
        } = state.project

        nonWorkingDays[ i ] = `${value}T05:00:00.000Z`

        setState({
            ...state,
            project
        })
    }

    const resetProjectCard = () => {

        const {
            name,
            shortName,
            address,
            cost,
            startsAt,
            endsAt,
            code,
        } = project

        const reseted = {
            ...state.project,
            name,
            shortName,
            address,
            cost,
            startsAt,
            endsAt,
            code,
        }

        setState({
            ...state,
            project: reseted,
            editingProject: false,
        })
    }

    const resetClientCard = () => {

        const {
            name,
            address,
            email,
            phone,
        } = project.client

        const resetedClient = {
            ...state.project.client,
            name,
            address,
            email,
            phone,
        }

        const resetedProject = {
            ...state.project,
            client: resetedClient,
        }

        setState({
            ...state,
            project: resetedProject,
            editingClient: false,
        })
    }

    const resetSupervisorCard = () => {

        const {
            name,
            address,
            email,
            phone,
        } = project.supervisor

        const resetedSupervisor = {
            ...state.project.supervisor,
            name,
            address,
            email,
            phone,
        }

        const resetedProject = {
            ...state.project,
            supervisor: resetedSupervisor,
        }

        setState({
            ...state,
            project: resetedProject,
            editingSupervisor: false,
        })
    }

    const resetSettingsCard = () => {

        const {
            method,
            lookahead,
            workTimeStart,
            workTimeEnd,
            workTimeAtWeek,
            workMonday,
            workTuesday,
            workWednesday,
            workThursday,
            workFriday,
            workSaturday,
            workSunday,
        } = project.settings

        const resetedSettings = {
            ...state.project.settings,
            method,
            lookahead,
            workTimeStart,
            workTimeEnd,
            workTimeAtWeek,
            workMonday,
            workTuesday,
            workWednesday,
            workThursday,
            workFriday,
            workSaturday,
            workSunday,
        }

        const resetedProject = {
            ...state.project,
            settings: resetedSettings,
        }

        setState({
            ...state,
            project: resetedProject,
            editingSettings: false,
        })
    }

    const saveProject = async () => {

        if (tab === 'SETTINGS') {

            if (daysEnabled() === 0) {

                alert('Selecciona los días de la semana de trabajo')
                return
            }

            if (!checkNonWorkingDays()) {

                alert('Ingresa correctamente los días no laborables')
                return
            }

            if (!checkTimeExtensions()) {

                alert('Ingresa correctamente las ampliaciones')
                return
            }
        }

        dispatch(InterfaceActions.showLoader())

        const res: any = await dispatch(ProjectsActions.updateAction(state.project))

        const [ error ] = res

        dispatch(InterfaceActions.hideLoader())

        if (error) {

            let err_msg = 'Error desconocido'

            if (error.response.data.error === 'invalid_start_date') {
                err_msg = 'La fecha de inicio no es válida'
            }

            if (error.response.data.error === 'invalid_end_date') {
                err_msg = 'La fecha de fin no es válida'
            }

            //@ts-ignore
            Toast.show('Error al actualizar el proyecto', err_msg)

            return
        }

        setEditable(false)
    }

    const addTimeExtension = () => {

        const project = state.project

        const timeExtensions = project.timeExtensions

        timeExtensions.push({
            days: undefined,
            reason: '',
            deleted: false,
        })

        project.timeExtensions = timeExtensions

        setState({
            ...state,
            project,
        })
    }

    const updateTimeExtension = (index: number, field: string, value: any) => {

        const {
            timeExtensions,
        } = state.project

        timeExtensions[index][field] = value

        setState({
            ...state,
            project
        })
    }

    const removeTimeExtension = (index: number) => {

        const {
            timeExtensions,
        } = state.project

        if (!timeExtensions[index]._id) {

            timeExtensions.splice(index, 1)
        } else {

            timeExtensions[index].deleted = true
        }

        project.timeExtensions = timeExtensions

        setState({
            ...state,
            project
        })
    }

    const startsAt = moment(state.project.startsAt, 'YYYY-MM-DD')
    const endsAt = moment(state.project.endsAt, 'YYYY-MM-DD')
    const nonWorkingDays = getNonWorkingDaysFromRange(state.project.startsAt, state.project.endsAt, state.project)
    const diff = DateFunctions.getDiffDays(state.project.startsAt, state.project.endsAt, nonWorkingDays)

    useEffect(() => {

        if (project.ability) ability.update(project.ability)
    }, [ project._id ])

    return (
        <MainLayout
            username={`${userData?.name} ${userData?.surname}`}
            project={state.project}
            menuBar={menu}
            subMenuBar={submenu}
            rightTitle={state.project.shortName}
            avatar={userData?.avatar ? `${config.apiHost}/${userData.avatar}` : ''}
        >
            <MainLayoutContentHeader>
                <div className='ul-widget__head-label flex-grow-1'>
                    <h3 className='ul-widget__head-title'>Proyecto</h3>
                </div>
                <div className='ul-widget__head-toolbar'>
                    <div className='text-right'>
                        <Can I="update" this="Project" ability={ability}>
                            {!editable && (
                                <button
                                    className="btn btn-link btn-link-header"
                                    onClick={() => setEditable(true)}
                                >
                                    <span className="icon-editar"></span> Editar
                                </button>
                            )}
                            {editable && (
                                <>
                                    <button
                                        className="btn btn-link btn-link-header"
                                        onClick={() => unSetEditable()}
                                    >
                                        <span className="icon-cancelar"></span> Cancelar
                                    </button>
                                    &nbsp;
                                    <button
                                        className="btn btn-link btn-link-header"
                                        onClick={() => saveProject()}
                                    >
                                        <span className="icon-guardar"></span> Guardar
                                    </button>
                                </>
                            )}
                        </Can>
                    </div>
                </div>
                <div className="ul-widget__head-nav">
                    <Tab.Container>
                        <Nav as="ul" className="nav-tabs nav-tabs-line nav-tabs-bold">
                            <Nav.Item as="li">
                                <Nav.Link active={tab === 'PROJECT'} onClick={() => changeTab('PROJECT')}>
                                    <span className="icon-datos_proyecto"></span> <span className="hide-on-mobile"> Proyecto</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Nav.Link active={tab === 'CLIENT'} onClick={() => changeTab('CLIENT')}>
                                    <span className="icon-cliente"></span> <span className="hide-on-mobile"> Cliente</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Nav.Link active={tab === 'SUPERVISOR'} onClick={() => changeTab('SUPERVISOR')}>
                                    <span className="icon-supervision"></span><span className="hide-on-mobile"> Supervisor</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Nav.Link active={tab === 'SETTINGS'} onClick={() => changeTab('SETTINGS')}>
                                    <span className="icon-configuracion"></span><span className="hide-on-mobile"> Configuración</span>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Tab.Container>
                </div>
            </MainLayoutContentHeader>
            <MainLayoutContentBody autoSize={true}>
                <Tab.Content className="no-padding">
                    <Tab.Pane active={tab === 'PROJECT'}>
                        <Row>
                            <Col md={8}>
                                <Card className='table-card'>
                                    <Card.Body>
                                        <div className='row'>
                                            <div className="col-md-8 form-group mb-3">
                                                <label htmlFor="large-name">Nombre completo</label>
                                                <InputFormControl
                                                    id="large-name"
                                                    placeholder="Nombre completo del proyecto"
                                                    value={state.project.name}
                                                    onChange={(value: string) => handleProject({ ...state.project, name: value })}
                                                    readOnly={!editable}
                                                />
                                            </div>
                                            <div className="col-md-4 form-group mb-3">
                                                <label htmlFor="short-name">Nombre corto</label>
                                                <InputFormControl
                                                    id="short-name"
                                                    placeholder="Nombre corto del proyecto"
                                                    value={state.project.shortName}
                                                    onChange={(value: string) => handleProject({ ...state.project, shortName: value })}
                                                    readOnly={!editable}
                                                />
                                            </div>
                                            <div className="col-md-8 form-group mb-3">
                                                <label htmlFor="direction">Dirección</label>
                                                <InputFormControl
                                                    id="direction"
                                                    placeholder="Dirección del proyecto"
                                                    value={state.project.address}
                                                    onChange={(value: string) => handleProject({ ...state.project, address: value })}
                                                    readOnly={!editable}
                                                />
                                            </div>
                                            <div className="col-md-4 form-group mb-3">
                                                <label htmlFor="cost">Costo</label>
                                                <InputMaskedControl
                                                    id="cost"
                                                    placeholder="Costo del proyecto"
                                                    value={state.project.cost}
                                                    onChange={(e: any) => handleProject({ ...state.project, cost: e.target.value })}
                                                    readOnly={!editable}
                                                />
                                            </div>
                                            <div className="col-md-4 form-group mb-3">
                                                <label htmlFor="startsAt">Inicio</label>
                                                <InputFormControl
                                                    type="date"
                                                    id="startsAt"
                                                    placeholder="Inicio del proyecto"
                                                    value={startsAt.format('YYYY-MM-DD')}
                                                    onChange={(value: string) => handleProject({ ...state.project, startsAt: value })}
                                                    readOnly={!editable}
                                                />
                                            </div>
                                            <div className="col-md-4 form-group mb-3">
                                                <label htmlFor="fin">Fin</label>
                                                <InputFormControl
                                                    type="date"
                                                    id="endsAt"
                                                    placeholder="Fin del proyecto"
                                                    value={endsAt.format('YYYY-MM-DD')}
                                                    onChange={(value: string) => handleProject({ ...state.project, endsAt: value })}
                                                    readOnly={!editable}
                                                />
                                            </div>
                                            <div className="col-md-4 form-group mb-3">
                                                <label htmlFor="plazo">Plazo del proyecto</label>
                                                <InputFormControl
                                                    id="plazo"
                                                    value={`${diff} días`}
                                                    onChange={(value: string) => null}
                                                    readOnly
                                                />
                                            </div>
                                            <div className="col-md-4 form-group mb-3">
                                                <label htmlFor="plazo">Código de proyecto</label>
                                                <InputFormControl
                                                    id="code"
                                                    value={state.project.code}
                                                    onChange={(value: string) => handleProject({ ...state.project, code: value })}
                                                    readOnly={!editable}
                                                />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Tab.Pane>
                    <Tab.Pane active={tab === 'CLIENT'}>
                        <Row>
                            <Col md={6}>
                                <Card>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-xl-12 col-md-12 form-group mb-3">
                                                <label>Nombre / Razón Social</label>
                                                <InputFormControl
                                                    value={state.project.client.name || ''}
                                                    onChange={(value: string) => handleClient({ ...state.project.client, name: value })}
                                                    readOnly={!editable}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-12 col-md-12 form-group mb-3">
                                                <label>Dirección</label>
                                                <InputFormControl
                                                    value={state.project.client.address}
                                                    onChange={(value: string) => handleClient({ ...state.project.client, address: value })}
                                                    readOnly={!editable}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-8 col-md-12 col-sm-8 form-group mb-3">
                                                <label>Email</label>
                                                <InputFormControl
                                                    type="email"
                                                    value={state.project.client.email}
                                                    onChange={(value: string) => handleClient({ ...state.project.client, email: value })}
                                                    readOnly={!editable}
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-12 col-sm-4 form-group mb-3">
                                                <label>Teléfono</label>
                                                <InputFormControl
                                                    type="tel"
                                                    value={state.project.client.phone}
                                                    onChange={(value: string) => handleClient({ ...state.project.client, phone: value })}
                                                    readOnly={!editable}
                                                />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Tab.Pane>
                    <Tab.Pane active={tab === 'SUPERVISOR'}>
                        <Row>
                            <Col md={6}>
                                <Card>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-xl-12 col-md-12 form-group mb-3">
                                                <label>Nombres y Apellidos</label>
                                                <InputFormControl
                                                    value={state.project.supervisor.name}
                                                    onChange={(value: string) => handleSupervisor({ ...state.project.supervisor, name: value })}
                                                    readOnly={!editable}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-12 col-md-12 form-group mb-3">
                                                <label>Dirección</label>
                                                <InputFormControl
                                                    value={state.project.supervisor.address}
                                                    onChange={(value: string) => handleSupervisor({ ...state.project.supervisor, address: value })}
                                                    readOnly={!editable}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-8 col-md-12 col-sm-8 form-group mb-3">
                                                <label>Email</label>
                                                <InputFormControl
                                                    type="email"
                                                    value={state.project.supervisor.email}
                                                    onChange={(value: string) => handleSupervisor({ ...state.project.supervisor, email: value })}
                                                    readOnly={!editable}
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-12 col-sm-4 form-group mb-3">
                                                <label>Teléfono</label>
                                                <InputFormControl
                                                    type="tel"
                                                    value={state.project.supervisor.phone}
                                                    onChange={(value: string) => handleSupervisor({ ...state.project.supervisor, phone: value })}
                                                    readOnly={!editable}
                                                />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Tab.Pane>
                    <Tab.Pane active={tab === 'SETTINGS'}>
                        <Row>
                            <Col md={4}>
                                <Card>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-md-6 form-group mb-3">
                                                <label htmlFor="method">Método de Seguimiento</label>
                                                {!state.project.settings ? null : (
                                                    <SelectFormControl
                                                        id="method"
                                                        value={state.project.settings.method}
                                                        onChange={(value: string) => handleMethod(value)}
                                                        disabled={!editable}
                                                    >
                                                        <option value="Gantt">Gantt</option>
                                                    </SelectFormControl>
                                                )}
                                            </div>
                                            <div className="col-md-6 form-group mb-3">
                                                <label htmlFor="method">Periodo Lookahead</label>
                                                {!state.project.settings ? null : (
                                                    <SelectFormControl
                                                        id="method"
                                                        value={state.project.settings.lookahead}
                                                        onChange={(value: string) => handleLookahead(value)}
                                                        disabled={!editable}
                                                    >
                                                        <option value="3">3 Semanas</option>
                                                        <option value="4">4 Semanas</option>
                                                        <option value="5">5 Semanas</option>
                                                        <option value="6">6 Semanas</option>
                                                    </SelectFormControl>
                                                )}
                                            </div>
                                            <div className="col-12 form-group mb-3">
                                                <label htmlFor="">Semana de trabajo</label>
                                                <div className="row">
                                                    {!state.project.settings ? null : (
                                                        <div className="col-12">
                                                            <label className="checkbox checkbox-primary">
                                                                <input type="checkbox"
                                                                    checked={state.project.settings.workMonday}
                                                                    onChange={() => toggleWorkDay('workMonday')}
                                                                    disabled={!editable || state.project.hasTasks}
                                                                />
                                                                <span>Lunes</span>
                                                                <span className="checkmark"></span>
                                                            </label>
                                                            <label className="checkbox checkbox-primary">
                                                                <input type="checkbox"
                                                                    checked={state.project.settings.workTuesday}
                                                                    onChange={() => toggleWorkDay('workTuesday')}
                                                                    disabled={!editable || state.project.hasTasks}
                                                                />
                                                                <span>Martes</span>
                                                                <span className="checkmark"></span>
                                                            </label>
                                                            <label className="checkbox checkbox-primary">
                                                                <input type="checkbox"
                                                                    checked={state.project.settings.workWednesday}
                                                                    onChange={() => toggleWorkDay('workWednesday')}
                                                                    disabled={!editable || state.project.hasTasks}
                                                                />
                                                                <span>Miercoles</span>
                                                                <span className="checkmark"></span>
                                                            </label>
                                                            <label className="checkbox checkbox-primary">
                                                                <input type="checkbox"
                                                                    checked={state.project.settings.workThursday}
                                                                    onChange={() => toggleWorkDay('workThursday')}
                                                                    disabled={!editable || state.project.hasTasks}
                                                                />
                                                                <span>Jueves</span>
                                                                <span className="checkmark"></span>
                                                            </label>
                                                            <label className="checkbox checkbox-primary">
                                                                <input type="checkbox"
                                                                    checked={state.project.settings.workFriday}
                                                                    onChange={() => toggleWorkDay('workFriday')}
                                                                    disabled={!editable || state.project.hasTasks}
                                                                />
                                                                <span>Viernes</span>
                                                                <span className="checkmark"></span>
                                                            </label>
                                                            <label className="checkbox checkbox-primary">
                                                                <input type="checkbox"
                                                                    checked={state.project.settings.workSaturday}
                                                                    onChange={() => toggleWorkDay('workSaturday')}
                                                                    disabled={!editable || state.project.hasTasks}
                                                                />
                                                                <span>Sábado</span>
                                                                <span className="checkmark"></span>
                                                            </label>
                                                            <label className="checkbox checkbox-primary">
                                                                <input type="checkbox"
                                                                    checked={state.project.settings.workSunday}
                                                                    onChange={() => toggleWorkDay('workSunday')}
                                                                    disabled={!editable || state.project.hasTasks}
                                                                />
                                                                <span>Domingo</span>
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-12 form-group">
                                                <label htmlFor="">Horario de trabajo</label>
                                                {!state.project.settings ? null : (
                                                    <>
                                                        <div className="row mb-1">
                                                            <div className="col-8 col-md-4 col-lg-3 col-xl-6 mb-2">
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                            Inicio
                                                                        </span>
                                                                    </div>
                                                                    <InputFormControl
                                                                        type="time"
                                                                        placeholder="Hora de inicio"
                                                                        value={state.project.settings.workTimeStart}
                                                                        onChange={(value: string) => handleWorktime('start', value)}
                                                                        readOnly={!editable}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-8 col-md-4 col-lg-3 col-xl-6 mb-2">
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                            Fin
                                                                        </span>
                                                                    </div>
                                                                    <InputFormControl
                                                                        type="time"
                                                                        placeholder="Hora fin"
                                                                        value={state.project.settings.workTimeEnd}
                                                                        onChange={(value: string) => handleWorktime('end', value)}
                                                                        readOnly={!editable}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-10 col-md-4 col-lg-4 col-xl-7 mb-2">
                                                                <div className="input-group">
                                                                    <InputFormControl
                                                                        type="number"
                                                                        placeholder="Horas semanales"
                                                                        value={state.project.settings.workTimeAtWeek}
                                                                        onChange={(value: string) => handleWorkTimeAtWeek(value)}
                                                                        readOnly={!editable}
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text">Horas semanales</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={4}>
                                <Card>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-12 form-group mb-3">
                                                <label htmlFor="">Horas extra</label>
                                                <div className="row">
                                                    {!state.project.overtime ? null : (
                                                        <>
                                                            {state.project.overtime.length ? null : (
                                                                <>
                                                                    <div className="col-12 mb-2">
                                                                        <InputFormControl
                                                                            type="text"
                                                                            value="No hay horas extra"
                                                                            onChange={() => null}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                            {state.project.overtime.map((_time: any, i: number) => (
                                                                <>
                                                                    <div className="col-12 col-md-4 col-lg-3 mb-2">
                                                                        <InputFormControl
                                                                            type="time"
                                                                            placeholder="Hora de inicio"
                                                                            value={state.project.settings.workTimeStart}
                                                                            onChange={() => null}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                    <div className="col-12 col-md-4 col-lg-3 mb-2">
                                                                        <InputFormControl
                                                                            type="time"
                                                                            placeholder="Hora fin"
                                                                            value={state.project.settings.workTimeEnd}
                                                                            onChange={() => null}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                    <div className="col-12 col-md-4 col-lg-3 mb-2">
                                                                        <InputFormControl
                                                                            type="number"
                                                                            placeholder="Total"
                                                                            value={state.project.settings.workTimeAtWeek}
                                                                            onChange={() => null}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </>
                                                            ))}
                                                            {!project.isMine ? null : (
                                                                <div className="col-12 mb-3">
                                                                    <button className="btn btn-secondary"
                                                                        disabled={!editable}>
                                                                        <span className="icon-agregar"></span> Agregar horas extra
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>

                                            </div>
                                            <div className="col-12 form-group mb-3">
                                                <label htmlFor="">Días no laborables</label>
                                                {(!state.project.nonWorkingDays || state.project.nonWorkingDays.length === 0) && (
                                                    <div className="row">
                                                        <div className="col-12 mb-2">
                                                            <InputFormControl
                                                                type="text"
                                                                value="No hay días no laborables"
                                                                onChange={() => null}
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                {!state.project.nonWorkingDays ? null : (
                                                    <>
                                                        {state.project.nonWorkingDays.map((day: any, i: number) => (
                                                            <div className="row" key={i}>
                                                                <div className="col-12 col-lg-6 mb-2">
                                                                    <InputFormControl
                                                                        type="date"
                                                                        placeholder="Hora de inicio"
                                                                        value={moment.utc(day).format('YYYY-MM-DD')}
                                                                        onChange={(value: any) => updateNonWorkingDay(i, value)}
                                                                        onRemove={() => removeNonWorkingDay(i)}
                                                                        readOnly={!editable}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                        {!project.isMine ? null : (
                                                            <div className="row">
                                                                <div className="col-12 mb-3">
                                                                    <button
                                                                        className="btn btn-secondary"
                                                                        onClick={() => addNonWorkingDay()}
                                                                        disabled={!editable}
                                                                    >
                                                                        <span className="icon-agregar"></span> Agregar día
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Tab.Pane>
                </Tab.Content>
            </MainLayoutContentBody>
        </MainLayout>
    )
}

export default ProjectInfoPage
