
import { AxiosError } from 'axios'
import ProjectsService from '../../Services/ProjectsService'

import { ProjectType } from '../../Types/Interfaces'

export const clearAllProjectsAction = () => {

    return {
        type: 'PROJECTS_CLEAR_ALL'
    }
}

const clearProjectAction = () => {

    return {
        type: 'PROJECTS_CLEAR_ONE'
    }
}

const getResultAction = () => {

    return async (dispatch: any) => {

        dispatch({
            type: 'PROJECTS_LOAD_RESULT_START'
        })

        try {

            const res = await ProjectsService.getResult()

            dispatch({
                type: 'PROJECTS_LOAD_RESULT_END',
                content: {
                    data: res.data
                }
            })

        } catch (error) {

            //todo: remove try catch

            dispatch({
                type: 'PROJECTS_LOAD_RESULT_ERROR',
                content: {
                    error: error instanceof Error ? error.message : 'unknown error'
                }
            })
        }
    }
}

const getRowAction = (_id: string) => {

    return async (dispatch: any) => {

        dispatch({
            type: 'PROJECTS_LOAD_ROW_START'
        })

        dispatch({
            type: 'TASK_LIST_RESET'
        })

        try {
            const res = await ProjectsService.getRow(_id)

            if (res.data.archived) {
                location.href = '/'
            } else {
                dispatch({
                    type: 'PROJECTS_LOAD_ROW_END',
                    content: {
                        data: res.data
                    }
                })

                return res.data
            }

        } catch (error) {

            console.log('something went wrong', { error })

            if (error instanceof AxiosError) {

                if (error.response?.status === 401) {

                    location.href = '/'
                }
            }

            throw error
        }
    }
}

const createAction = (data: ProjectType) => {

    return async (dispatch: any) => {

        dispatch({
            type: 'PROJECTS_CREATE_START'
        })

        try {

            await ProjectsService.create(data)

            dispatch({
                type: 'PROJECTS_CREATE_END'
            })
        } catch (error) {

            //todo: remove try catch

            dispatch({
                type: 'PROJECTS_CREATE_ERROR',
                content: {
                    error: error instanceof Error ? error.message : 'unknown error'
                }
            })
        }
    }
}

const updateAction = (data: ProjectType) => {

    return async (dispatch: any) => {

        dispatch({
            type: 'PROJECT_UPDATE_START'
        })

        try {

            await ProjectsService.update(data)

            dispatch({
                type: 'PROJECT_UPDATE_END',
                content: {
                    data
                }
            })

            return [null, data]
        } catch (error) {

            //todo: remove try catch

            dispatch({
                type: 'PROJECT_UPDATE_ERROR',
                content: {
                    error: error instanceof Error ? error.message : 'unknown error'
                }
            })

            return [error, null]
        }
    }
}

const deleteAction = (project_id: string) => {

    return async (dispatch: any) => {

        dispatch({
            type: 'PROJECT_DELETE_START',
            project_id,
        })

        try {

            await ProjectsService.del(project_id)

            dispatch({
                type: 'PROJECT_DELETE_END'
            })

            dispatch(getResultAction())
        } catch (error) {

            if (error instanceof Error) {

                dispatch({
                    type: 'PROJECT_DELETE_ERROR',
                    content: {
                        error: error.message
                    }
                })
            }
        }
    }
}

const abandonAction = (project_id: string) => {

    return async (dispatch: any) => {

        dispatch({
            type: 'PROJECT_ABANDON_START'
        })

        try {

            const res = await ProjectsService.abandon(project_id)

            dispatch({
                type: 'PROJECT_ABANDON_END'
            })

            dispatch(getResultAction())
        } catch (error) {

            if (error instanceof Error) {

                dispatch({
                    type: 'PROJECT_ABANDON_ERROR',
                    content: {
                        error: error.message
                    }
                })
            }
        }
    }
}

const delegateAction = (project_id: string, asistenteSeleccionadoId: any) => {

    return async (dispatch: any) => {

        dispatch({
            type: 'PROJECT_DELEGATE_START'
        })

        try {

            const res = await ProjectsService.delegate(project_id, asistenteSeleccionadoId)

            dispatch({
                type: 'PROJECT_DELEGATE_END'
            })

            dispatch(getResultAction())
        } catch (error) {

            if (error instanceof Error) {

                dispatch({
                    type: 'PROJECT_ABANDON_ERROR',
                    content: {
                        error: error.message
                    }
                })
            }
        }
    }
}


const updateCodes = (codes: any) => {

    return {
        type: 'PROJECT_UPDATE_CODES',
        codes
    }
}

const updateSettings = (settings_id: string, settings: any) => {

    return async (dispatch: any) => {

        try {

            const res = await ProjectsService.updateSettings(settings_id, settings)

            if (res.success) {

                dispatch({
                    type: 'USER_PROJECT_SETTINGS_UPDATE',
                    payload: res.data
                })

                return true
            }
        } catch (error) {

            return false
        }
    }
}

export default {
    clearAllProjects: clearAllProjectsAction,
    clearProjectAction,
    getResultAction,
    getRowAction,
    createAction,
    updateAction,
    deleteAction,
    abandonAction,
    delegateAction,

    updateCodes,
    updateSettings,
}
